import { firebase1 } from '../index';
export const ACTION_GET_UPLOAD_PERSENTAGE = 'ACTION_GET_UPLOAD_PERSENTAGE';
const spProductsCollection = `sp_products_${process.env.NODE_ENV}` || 'sp_products_development';

export const getCatalogUploadPercentage = async dispatch => {
    if (localStorage.getItem('catalogUploadStatus')) {
        const catalogUploadStatus = JSON.parse(localStorage.getItem('catalogUploadStatus'));
        const firestore = firebase1.firestore();
        const fsRes = firestore
            .collection(spProductsCollection)
            .where('batchIdentifier', '==', catalogUploadStatus.batchIdentifier);
            console.log("fsRes",fsRes);
        fsRes.onSnapshot(snapshot => {
            const total = snapshot.docs.length;
            const totalRecords = catalogUploadStatus.totalRecords;
            const validRecords = catalogUploadStatus.validRecords;
            const percentage = (total / validRecords) * 100;
            dispatch({
                type: ACTION_GET_UPLOAD_PERSENTAGE,
                data: {
                    completedPercentage: Math.round(percentage),
                    totalRecords: totalRecords,
                    totalSuccess: total,
                }
            });
        });
    }
};
