
export const pageSizes = [200];

export const buyerRuleType = 'buyer';
export const sourcingRuleType = 'sourcing';
export const adminRuleType = 'admin';

export const ruleAllowForUserEdit = [1, 6, 55, 56];

export const buyerCheckRoleIds = [1, 61, 6, 56, 355];
export const qcCheckRoleIds = [1, 57, 58, 55, 56, 144, 944];
export const seniorBuyerRoleIds = [1, 6];
export const adminRoleId = [1];
export const sourcingHeadRoleIds = [1, 55];
export const qcManagerRoleIds = [58];

export const parentRuleMapping = {
    1: {
        type: 'Super Admin',
        color: 'primary',
        ruleType: adminRuleType
    },
    6: {
        type: 'Senior Buyer',
        color: 'secondary',
        ruleType: buyerRuleType
    },
    61: {
        type: 'Buyer',
        color: 'secondary',
        ruleType: buyerRuleType
    },
    55: {
        type: 'Sourcing Head',
        color: 'primary',
        ruleType: sourcingRuleType
    },
    56: {
        type: 'Sourcing Merchandiser',
        color: 'primary',
        ruleType: sourcingRuleType
    },
    57: {
        type: 'QC Executive',
        color: 'primary',
        ruleType: sourcingRuleType
    },
    58: {
        type: 'QC Manager',
        color: 'primary',
        ruleType: sourcingRuleType
    }
};
