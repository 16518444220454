import { isEmpty } from '../../../helpers/Utils';
import {
  ACTION_GET_ATTRIBUTES_SUCCESS,
  ACTION_SET_PAGE_INDEX_ATTRIBUTES,
  ACTION_GET_ATTRIBUTES_SET_SUCCESS,
  ACTION_SET_SELECTION_ATTRIBUTES_SET,
  ACTION_SET_PAGE_INDEX_ATTRIBUTES_SET,
  ACTION_SET_SELECTED_SIZE_BLOCK_CODE_SET,
} from './actions';

export const initialState = {
  attributes: [],
  attributesSets: [],
  selectedAttributeSet: {},
  attributeSetPageIndex: 0,
  attributePageIndex: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_ATTRIBUTES_SUCCESS: {
      const { data } = action;
      const attributesMap = {};

      // data.response.sort((a, b) => (a.frontend_label || '').localeCompare(b.frontend_label));
      // data.response.map(v => {
      //   attributesMap[v.id] = v;
      // });
      return {
        ...state,
        attributes: data.data,
        // attributesMap: attributesMap
      };
    }

    case ACTION_SET_PAGE_INDEX_ATTRIBUTES: {
      const { data } = action;
      return {
        ...state,
        attributePageIndex: data,
      };
    }

    case ACTION_GET_ATTRIBUTES_SET_SUCCESS: {
      const { data } = action;
      const attributesSetsMap = {};
      data.response.map((set) => {
        const groups = new Map();
        if (!isEmpty(groups)) {
          if(set?.groups){
            const groupNames = Object.keys(set.groups);
            groupNames.forEach(groupName => {
              groups[groupName] = set.groups[groupName] || [];
            });
            set.groups = groups;
            attributesSetsMap[set.attribute_set_id] = set;
          }
        }
      });

      return {
        ...state,
        attributesSets: data.response,
        attributesSetsMap,
      };
    }

    case ACTION_SET_SELECTION_ATTRIBUTES_SET: {
      const { data } = action;
      return {
        ...state,
        selectedAttributeSet: data,
      };
    }

    case ACTION_SET_PAGE_INDEX_ATTRIBUTES_SET: {
      const { data } = action;
      return {
        ...state,
        attributeSetPageIndex: data,
      };
    }

    case ACTION_SET_SELECTED_SIZE_BLOCK_CODE_SET: {
      const { data } = action;
      console.log('====data===', data);
      return {
        ...state,
        selectedAttributeSet: data,
      };
    }
    default: {
      return state;
    }
  }
};
