import React, { createContext, useContext, useReducer } from 'react';

import AttributesReducer, { initialState as AttributesState } from '../views/app/attributes/reducer';
import CatalogUploadPersentageReducer, { initialState as CatalogUploadPersentageState } from '../views/app/catalog-upload/reducer';
import SasoUploadPercentageReducer, { initialState as SasoUploadPercentageState } from '../views/app/saso-upload/reducer';

export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  return (
    <AppContext.Provider
      value={{
        attributes: useReducer(AttributesReducer, AttributesState),
        catalogUploadPersentage: useReducer(CatalogUploadPersentageReducer, CatalogUploadPersentageState),
        sasoUploadPercentage: useReducer(SasoUploadPercentageReducer, SasoUploadPercentageState),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useStateValue = () => useContext(AppContext);
