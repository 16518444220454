import { AbilityBuilder } from '@casl/ability';

const defineRulesFor = permissions => {
  //   const { can, rules } = AbilityBuilder.extract();
  const { can, rules } = new AbilityBuilder();

  permissions?.length &&
    permissions.forEach(permission => {
      can(`${permission.type}`, `${permission.target}`);
    });

  return rules;
};

export default defineRulesFor;
