import React, {
  Suspense, useEffect, useState, lazy,
} from 'react';
import {
  Route, withRouter, Switch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { Ability } from '@casl/ability';
import firebase from 'firebase/app';
import AppLayout from '../../layout/AppLayout';
import {
  getCurrentUser, axiosPost, updateUserInfo, retry,
} from '../../helpers/Utils';
import { appApiPath, firebaseConfig1 } from '../../constants/defaultValues';
import { AbilityContext } from './components/Abilities';
import defineRulesFor from './components/permissions';

import { AppProvider } from '../../context/App';
// import { ProtectedRoute, UserRole } from '../../helpers/authHelper';
import 'firebase/firestore';

export const firebase1 = firebase.initializeApp(firebaseConfig1, 'secondary');

const Dashboard = lazy(() => import(/* webpackChunkName: "viwes-dashboard" */ './dashboard'));
const CatalogUpload = React.lazy(() => import(/* webpackChunkName: "viwes-catalog-upload" */ './catalog-upload'));
const Supplier = React.lazy(() => import(/* webpackChunkName: "viwes-supplier" */ './supplier'));
const Products = lazy(() => import(/* webpackChunkName: "viwes-products" */ './product'));
const Users = lazy(() => import(/* webpackChunkName: "viwes-users" */ './users'));

const Catalog = lazy(() => import(/* webpackChunkName: "viwes-users" */ './catalog'));
const Orders = lazy(() => import(/* webpackChunkName: "viwes-users" */ './orders'));
const Quote = lazy(() => import(/* webpackChunkName: "viwes-users" */ './quote'));
const Amendment = lazy(() => import(/* webpackChunkName: "viwes-users" */ './amendment'));
const Approval = lazy(() => import(/* webpackChunkName: "viwes-users" */ './approval'));
const Report = lazy(() => import(/* webpackChunkName: "viwes-users" */ './report'));
const Resource = lazy(() => import(/* webpackChunkName: "viwes-users" */ './resource'));
const SourcingListV2 = lazy(() => import(/* webpackChunkName: "viwes-users" */ './product/sourcing-list-v2'));
const ASNDetails = lazy(() => import(/* webpackChunkName: "viwes-users" */ './product/asn-details'));

const Attributes = lazy(() => retry(() => import('./attributes/routes')));
// const Attributes = lazy(() => () => import('./attributes/routes'));

const SecondMenu = lazy(() => import(/* webpackChunkName: "viwes-second-menu" */ './second-menu'));
const BlankPage = lazy(() => import(/* webpackChunkName: "viwes-blank-page" */ './blank-page'));

const Techpack = React.lazy(() => import(/* webpackChunkName: "viwes-supplier" */ './tech-pack'));

const Sasoupload = React.lazy(() => import(/* webpackChunkName: "viwes-supplier" */ './saso-upload'));
const Setting = lazy(() => retry(() => import('./size-block-code/routes')));
const App = ({ match }) => {
  console.log('match', match);
  const [status, setStatus] = useState(false);
  const [previousLocation, setpreviousLocation] = useState(null);
  const [rules, setRules] = useState(undefined);
  const [ability, setAbility] = useState(new Ability());

  useEffect(() => {
    setpreviousLocation(location.pathname);
    if (location.pathname !== previousLocation) {
      setStatus(false);
    }
    (async () => {
      const userInfo = getCurrentUser();

      if (!userInfo || typeof userInfo !== 'object') {
        setStatus(false);
      } else {
        setStatus(true);
      }

      const allowAll = userInfo.roleType == 'U'
        ? [{ type: 'manage', target: 'all' }]
        : []; // TODO : Update This Logic
      const rules = defineRulesFor(allowAll);
      ability.update(rules);
      setAbility(ability);
      setRules(rules);
    })();
  }, [location.pathname]);
  return (
    <AbilityContext.Provider value={ability}>
      <AppProvider>
        <AppLayout>
          <div className="dashboard-wrapper">
            {!status ? (
              <div className="loading" />
            ) : (
              <Suspense fallback={<div className="loading" />}>
                <Switch>
                  <Redirect
                    exact
                    from={`${match.url}/`}
                    to={`${match.url}/dashboard`}
                  />
                  <Route
                    path={`${match.url}/product`}
                    render={(props) => <Products {...props} />}
                  />
                  <Route
                    path={`${match.url}/sourcing`}
                    render={(props) => <SourcingListV2 {...props} />}
                  />
                  <Route
                    path={`${match.url}/asn-details/:asn_number`}
                    render={(props) => <ASNDetails {...props} />}
                  />
                  <Route
                    path={`${match.url}/catalog-upload`}
                    render={(props) => <CatalogUpload {...props} />}
                  />
                  <Route
                    path={`${match.url}/orders`}
                    render={(props) => <Orders {...props} />}
                  />
                  <Route
                    path={`${match.url}/quote`}
                    render={(props) => <Quote {...props} />}
                  />
                  <Route
                    path={`${match.url}/amendment`}
                    render={(props) => <Amendment {...props} />}
                  />
                  <Route
                    path={`${match.url}/report`}
                    render={(props) => <Report {...props} />}
                  />
                  <Route
                    path={`${match.url}/approval`}
                    render={(props) => <Approval {...props} />}
                  />
                  <Route
                    path={`${match.url}/resource`}
                    render={(props) => <Resource {...props} />}
                  />
                  <Route
                    path={`${match.url}/supplier`}
                    render={(props) => <Supplier {...props} />}
                  />
                  <Route
                    path={`${match.url}/users`}
                    render={(props) => <Users {...props} />}
                  />
                  <Route
                    path={`${match.url}/techpack`}
                    render={(props) => <Techpack {...props} />}
                  />
                  <Route
                    path={`${match.url}/sasoupload`}
                    render={(props) => <Sasoupload {...props} />}
                  />
                  <Route
                    path={`${match.url}/catalog`}
                    render={(props) => <Catalog {...props} />}
                  />
                  <Route
                    path={`${match.url}/attributes`}
                    render={(props) => <Attributes {...props} />}
                  />
                   <Route
                    path={`${match.url}/settings`}
                    render={(props) => <Setting {...props} />}
                  />
                  <Route
                    path={`${match.url}/second-menu`}
                    render={(props) => <SecondMenu {...props} />}
                  />
                  <Route
                    path={`${match.url}/blank-page`}
                    render={(props) => <BlankPage {...props} />}
                  />
                  <Route
                    path={`${match.url}/`}
                    render={(props) => <Dashboard {...props} />}
                  />
                  
                </Switch>
              </Suspense>
            )}
          </div>
        </AppLayout>
      </AppProvider>
    </AbilityContext.Provider>
  );
};

const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(connect(mapStateToProps, {})(App));
