import { adminRoot, magnetoRedirectURL } from './defaultValues';
import { buyerCheckRoleIds, qcCheckRoleIds, seniorBuyerRoleIds, sourcingHeadRoleIds, adminRoleId, qcManagerRoleIds } from './userValues';

const data = [
  {
    id: 'dashboard',
    icon: 'iconsminds-dashboard',
    label: 'menu.dashboard',
    to: `${adminRoot}/dashboard`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
 {
   id: 'catalog-upload',
   icon: 'iconsminds-upload-1',
   label: 'menu.catalog-upload',
   to: `${adminRoot}/catalog-upload`,
   visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
 },
  // {
  //   id: 'supplier',
  //   icon: 'iconsminds-user',
  //   label: 'menu.supplier',
  //   to: `${adminRoot}/supplier`,
  //   visibility: [...buyerCheckRoleIds],
  // },
  {
    id: 'buyer',
    icon: 'iconsminds-shopping-bag',
    label: 'menu.buyer',
    to: `${adminRoot}/product/product-list`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  //{
  //  id: 'sourcing',
  //  icon: 'iconsminds-security-check',
  //  label: 'menu.sourcing',
   // to: `${adminRoot}/sourcing`,
  //  visibility: [...qcCheckRoleIds],
 // },
  {
    id: 'users',
    icon: 'iconsminds-conference',
    label: 'menu.users',
    to: `${adminRoot}/users/user-list`,
    visibility: [...adminRoleId],
  },
  {
    id: 'orders',
    icon: 'iconsminds-security-check',
    label: 'menu.orders',
    to: `${adminRoot}/orders/order-list`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  {
    id: 'amendment',
    icon: 'iconsminds-security-check',
    label: 'menu.amendment',
    to: `${adminRoot}/amendment/amendment-list`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  {
    id: 'approval',
    icon: 'iconsminds-security-check',
    label: 'menu.approval',
    to: `${adminRoot}/approval/approval-list`,
    visibility: [...seniorBuyerRoleIds, ...sourcingHeadRoleIds],
  },
  {
    id: 'errorreport',
    icon: 'iconsminds-security-check',
    label: 'menu.errorreport',
    to: `${adminRoot}/report/report-list`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  {
    id: 'sasoupload',
    icon: 'iconsminds-upload',
    label: 'menu.sasoupload',
    to: `${adminRoot}/sasoupload/saso-upload`,
    visibility: [...qcCheckRoleIds],
  },
  {
    id: 'quote',
    icon: 'iconsminds-add-cart',
    label: 'menu.quote',
    to: `${adminRoot}/quote/quote-list`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  {
    id: 'techpack',
    icon: 'iconsminds-file-zip',
    label: 'menu.techpack',
    to: `${adminRoot}/techpack/tech-pack`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  {
    id: 'supplier',
    icon: 'iconsminds-file-zip',
    label: 'menu.supplier',
    to: `${adminRoot}/supplier/supplier-list`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  {
    id: 'resource',
    icon: 'iconsminds-add-cart',
    label: 'menu.resource',
    to: `${adminRoot}/resource/resource-history`,
    visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
  {
    id: 'catalog',
    icon: 'iconsminds-add-basket',
    label: 'menu.catalog',
    to: `${adminRoot}/catalog/category`,
    subs: [
      {
        label: "menu.products",
        to: `${adminRoot}/catalog/products`,
      },
      {
        label: "menu.category",
        to: `${adminRoot}/catalog/category`,
      },
    ],
    visibility: [...adminRoleId],
  },
  // {
  //   id: 'Attributes',
  //   icon: 'simple-icon-layers',
  //   label: 'menu.attributes',
  //   to: `${adminRoot}/pages/product/attributes`,
  //   subs: [
  //     {
  //       icon: 'simple-icon-options-vertical',
  //       label: 'menu.attributeset',
  //       to: `${adminRoot}/attributes/set`
  //     },

  //     {
  //       icon: 'iconsminds-tag-3',
  //       label: 'menu.attributes',
  //       to: `${adminRoot}/attributes/list`
  //     }
  //   ],
  //   visibility: [...adminRoleId],
  // },
  // {
  //   id: 'blankpage',
  //   icon: 'iconsminds-bucket',
  //   label: 'menu.blank-page',
  //   to: `${adminRoot}/blank-page`,
  // },
  // {
  //   id: 'docs',
  //   icon: 'iconsminds-library',
  //   label: 'Go Back',
  //   to: `${magnetoRedirectURL}`,
  //   newWindow: true,
  // },
  {
    id: 'Settings',
    icon: 'iconsminds-gear',
    label: 'menu.settings',
    to: `${adminRoot}/settings`,
    subs: [
      {
        label: 'menu.size-block-code',
        to: `${adminRoot}/settings/size-block-code`,
        visibility: [...adminRoleId, ...seniorBuyerRoleIds, ...sourcingHeadRoleIds, ...qcManagerRoleIds],
      },
      {
        label: 'menu.fcmapping',
        to: `${adminRoot}/settings/fcmapping`,
        visibility: [...adminRoleId, ...seniorBuyerRoleIds, ...sourcingHeadRoleIds], 
      }
    ],
    visibility: [...adminRoleId, ...seniorBuyerRoleIds, ...sourcingHeadRoleIds, ...qcManagerRoleIds], 
    // visibility: [...buyerCheckRoleIds, ...qcCheckRoleIds],
  },
];

if (process.env.SP_SOURCING_PAGE_FLAG === 'true') {
  data.splice(3, 0, {
    id: 'sourcing',
    icon: 'iconsminds-security-check',
    label: 'menu.sourcing',
    to: `${adminRoot}/sourcing`,
    visibility: [...qcCheckRoleIds],
  })
}
export default data;
