import { axiosPost } from '../../../helpers/Utils';
import { servicePath } from '../../../constants/defaultValues';

export const ACTION_GET_ATTRIBUTES_SUCCESS = 'ACTION_GET_ATTRIBUTES_SUCCESS';
export const ACTION_SET_PAGE_INDEX_ATTRIBUTES =
  'ACTION_SET_PAGE_INDEX_ATTRIBUTES';
export const ACTION_GET_ATTRIBUTES_SET_SUCCESS =
  'ACTION_GET_ATTRIBUTES_SET_SUCCESS';
export const ACTION_SET_SELECTION_ATTRIBUTES_SET =
  'ACTION_SET_SELECTION_ATTRIBUTES_SET';
export const ACTION_SET_PAGE_INDEX_ATTRIBUTES_SET =
  'ACTION_SET_PAGE_INDEX_ATTRIBUTES_SET';
export const ACTION_SET_SELECTED_SIZE_BLOCK_CODE_SET =
  'ACTION_SET_SELECTED_SIZE_BLOCK_CODE_SET';

const attributeApiUrl = `${servicePath}/attributes`;

export async function getList({ ruleId }, dispatch) {
  const result = await axiosPost(`${attributeApiUrl}/list`, {});
  dispatch({ type: ACTION_GET_ATTRIBUTES_SUCCESS, data: result.data });
}

export async function getSetsList({ ruleId }, dispatch) {
  // const result = await axios.post(
  //   `${SERVER_HOST}/v1/attribute/sets`,
  //   {},
  //   {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   }
  // );
  // dispatch({ type: ACTION_GET_ATTRIBUTES_SET_SUCCESS, data: result.data });
  const result = await axiosPost(`${attributeApiUrl}/sets`, {});
    let formatted_data = {
      status: result.data.status,
      statusCode: result.data.statusCode,
      statusMsg: result.data.statusMsg,
      response: result.data.response
    }

  dispatch({ type: ACTION_GET_ATTRIBUTES_SET_SUCCESS, data: formatted_data });
}

export async function createAttributeAction({ attribute }, dispatch) {
  const result = await axiosPost(`${attributeApiUrl}/create`, attribute);
  if (result.data.statusCode == 200) {
    await getList({}, dispatch);
  }
  return result;
}

export async function deleteAttributeAction({ attribute_code }, dispatch) {
  await axiosPost(`${attributeApiUrl}/delete`, {
    attribute_code,
  });
  getList({}, dispatch);
}

export async function updateAttributeSetAction({ attributeSet }, dispatch) {
  const result = await axiosPost(`${attributeApiUrl}/set`, attributeSet);
  getSetsList({}, dispatch);
}

export async function removeAttributeSetAction({ attributeSet }, dispatch) {
  const result = await axiosPost(`${attributeApiUrl}/removeSet`, attributeSet);
  getSetsList({}, dispatch);
}

export async function setSelectedAttributeSet({ attributeSet }, dispatch) {
  dispatch({ type: ACTION_SET_SELECTION_ATTRIBUTES_SET, data: attributeSet });
}

export async function setAttributeSetPaginationIndex({ index }, dispatch) {
  dispatch({ type: ACTION_SET_PAGE_INDEX_ATTRIBUTES_SET, data: index });
}

export async function setAttributePaginationIndex({ index }, dispatch) {
  dispatch({ type: ACTION_SET_PAGE_INDEX_ATTRIBUTES, data: index });
}

export async function setSelectedSizeBlockCodeSet({ data }, dispatch) {
  dispatch({ type: ACTION_SET_SELECTED_SIZE_BLOCK_CODE_SET, data });
}