import {
  ACTION_GET_UPLOAD_PERSENTAGE,
} from './actions';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_UPLOAD_PERSENTAGE: {
      const persentageData = action.data?.completedPercentage;
      const totalRecords = action.data?.totalRecords;
      const totalSuccess = action.data?.totalSuccess;
      return { 
        ...state,
        persentageData,
        totalRecords,
        totalSuccess,
      };
    }
    default: {
      return state;
    }
  }
};
