/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
} from './constants/defaultValues';
import { getCurrentColor, setCurrentColor, getCurrentUser } from './helpers/Utils';

import {
  CometChatUIKit,
  UIKitSettingsBuilder
} from "@cometchat/chat-uikit-react";
import { CometChat } from "@cometchat/chat-sdk-javascript";

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js');
//   });
// }

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.log('Service Worker registration failed:', error);
    });
}

const currentUser = getCurrentUser();
let UID = null
if (currentUser) {
  UID = `${currentUser.userId}_${currentUser.firstname.toLowerCase()}`;
}
const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(process.env.COMET_CHAT_APP_ID)
  .setRegion(process.env.COMET_CHAT_REGION)
  .setAuthKey(process.env.COMET_CHAT_AUTH_KEY)
  .subscribePresenceForFriends()
  .build();

CometChatUIKit.init(UIKitSettings).then(() => {
  console.log("Initialization completed successfully");
}).catch(console.log);


CometChat.getLoggedinUser().then(
  user => {
    console.log("User:", user);
    if (!user && UID) {
      CometChat.login(UID, process.env.COMET_CHAT_AUTH_KEY).then(
        (user) => {
          console.log("User Login indexxxxxx:", user);
        }, error => {
          console.log("Login failed with exception:", { error });
        }
      );
    }
  }, error => {
    console.log("Something went wrong", error);
  }
);

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();
