import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

import { adminRoot, UserRole, currentUser, appApiPath, roleMapping, firebaseConfigNotification } from '../../constants/defaultValues';
import { ruleAllowForUserEdit } from '../../constants/userValues';
import { axiosPost, setCurrentUser } from '../../helpers/Utils';

import firebase from 'firebase/app';
import "firebase/messaging";

let app;
const firebaseNotificationAppName = 'Notification';
if (!firebase.apps.some(app => app.name === firebaseNotificationAppName)) {
  app = firebase.initializeApp(firebaseConfigNotification, firebaseNotificationAppName);
} else {
  app = firebase.app(firebaseNotificationAppName);
}


export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (username, password) => {
  // eslint-disable-next-line no-return-await
  const _result = await axiosPost(`${appApiPath}/v1/login/user`, {
    username,
    password,
    isTerra: true
  });
  const data = (_result && _result.data) ? _result.data : {};
  console.log('data ', data);
  return data;
};

const compactChatLogin = (data) => {
  let UID = null;
  if (currentUser) {
    UID = `${data.userId}_${data.firstname.toLowerCase()}`;
  }

  const messaging = firebase.messaging(app);
  messaging.getToken({ vapidKey: process.env.NOTIFICATION_VAPID_KEY }).then((currentToken) => {
    if (currentToken) {
      console.log('Token: ', currentToken);
      CometChat.registerTokenForPushNotification(currentToken)
        .then((payload) => {
          console.log("CometChat.registerTokenForPushNotification", payload);
        })
        .catch((err) => {
          console.log("CometChat.registerTokenForPushNotification error", err);
        });
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });

  CometChat.getLoggedinUser().then(
    (user) => {
      if (!user && UID) {
        CometChat.login(UID, process.env.COMET_CHAT_AUTH_KEY).then(
          (user1) => {
            console.log('User Login:', user1);
          }, (error) => {
            console.log('Login failed with exception:', { error });
          },
        );
      }
    }, (error) => {
      console.log('Something went wrong', error);
    },
  );
};

function* loginWithEmailPassword({ payload }) {
  const { username, password } = payload.user;
  const { history } = payload;
  try {
    const data = yield call(loginWithEmailPasswordAsync, username, password);
    const loginUser = data.response;
    console.log('login ', loginUser);
    if (data.status) {
      const _userObject = {
        uid: loginUser.user.userId,
        id: loginUser.user.userId,
        title: `${loginUser.user.firstname} ${loginUser.user.lastname}`,
        img: '/assets/img/profiles/l-1.jpg',
        date: 'Last seen today 15:24',
        role: ruleAllowForUserEdit.includes(loginUser.user.parentRoleId)
          ? UserRole.Admin : UserRole.Editor,
        ...loginUser.user
      }
      const item = _userObject;
      setCurrentUser(item);
      console.log('user ', roleMapping[`${item.parentRoleId}`]);
      localStorage.setItem('userRole', roleMapping[`${item.parentRoleId}`]);
      localStorage.setItem('token', loginUser.token);
      compactChatLogin(item);
      yield put(loginUserSuccess(item));
      history.push('/app/product/product-list');
    } else {
      console.log('Login error ', data.message);
      yield put(loginUserError(data.message ?? ''));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .then(localStorage.removeItem("token"))
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
