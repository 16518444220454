import {
    ACTION_GET_SASO_FILE_UPLOAD_PERCENTAGE,
  } from './actions';
  
  export const initialState = {};
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case ACTION_GET_SASO_FILE_UPLOAD_PERCENTAGE: {
        const percentageData = action.data?.completedPercentage;
        const totalRecords = action.data?.totalRecords;
        const totalSuccess = action.data?.totalSuccess;
        return { 
          ...state,
          percentageData,
          totalRecords,
          totalSuccess,
        };
      }
      default: {
        return state;
      }
    }
  };
  