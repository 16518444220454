import React from 'react';
import clonedeep from 'lodash.clonedeep';

console.log(`process.env:\n`, process.env);

export const defaultGlobalState = {
  currentUser: {},
};
export const GlobalStateContext = React.createContext();

export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

export const defaultMenuType = 'menu-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563'
};
export const firebaseConfig1 = JSON.parse(process.env.CATALOG_UPLOAD_FIREBASE_CONFIG);
export const firebaseConfigNotification = JSON.parse(process.env.FIREBASE_INIT);

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
// export const servicePath = 'https://api.coloredstrategies.com';
export const servicePath = process.env.SP_API_BASE;
export const sizeBlockCodePath = process.env.SIZE_BLOCK_CODE_API_BASE;
export const appApiPath = process.env.SP_APP_API_BASE;
export const octaApiPath = process.env.OCTA_NEW_URL;
export const magnetoRedirectURL = 'https://stylishop.store/tyl30r3/'
export const lunaApiPath = process.env.SP_LUNA_API;

// export const { currentUser, setCurrentUserState } = useContext(UserContext);

export const currentUser = {
  id: 22,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
  territoryMapping: { territory: ['SA'] },
};
export const selectL4CategoryLimit = 5;
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.greenlime';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const roleMapping = {
  55: 'QC',
  56: 'QC',
  57: 'QC',
  58: 'QC',
  144: 'QC',
  6: 'Buyer',
  61: 'Buyer',
  62: 'Buyer',
  89: 'Buyer',
  1: 'Administrators'
};

export const deepClone = (input) => {
  if (input || typeof input === 'boolean') {
    return clonedeep(input);
  }
  return input;
};
