import { firebase1 } from '../index';
export const ACTION_GET_SASO_FILE_UPLOAD_PERCENTAGE =
  'ACTION_GET_SASO_FILE_UPLOAD_PERCENTAGE';
const spFileUploadBatchCollection = `sp_file_upload_batch_${process.env.NODE_ENV}` || 'sp_file_upload_batch_development';
// const spFileUploadBatchCollection = 'sp_file_upload_batch_qa';

export const getSasoUploadPercentage = async (dispatch) => {
  if (localStorage.getItem('sasoUploadStatus')) {
    const sasoUploadStatus = JSON.parse(
      localStorage.getItem('sasoUploadStatus')
    );
    const fdb = firebase1.firestore();

    const fsRes = fdb
      .collection(spFileUploadBatchCollection)
      .where('batchIdentifier', '==', sasoUploadStatus.batchIdentifier);
    fsRes.onSnapshot((snapshot) => {
      const total = snapshot.docs[0].data().totalSuccess;
      const totalRecords = sasoUploadStatus.totalRecords;
      const validRecords = sasoUploadStatus.validRecords;
      const percentage = (total / validRecords) * 100;
      dispatch({
        type: ACTION_GET_SASO_FILE_UPLOAD_PERCENTAGE,
        data: {
          completedPercentage: Math.round(percentage),
          totalRecords,
          totalSuccess: total,
        },
      });
    });
  }
};
